<template>
  <MasterDetail
    formTitle="Cadastro de Etapas/Atividades"
    :cols="cols"
    :opts="opts"
    :customResource="resource"
    descriptionProperty="atividade"
  ></MasterDetail>
</template>

<script>
import _ from "lodash";
import MasterDetail from "@/components/master-detail.vue";
export default {
  components: {
    MasterDetail,
  },
  computed: {
    resourceUrl: function () {
      return `/v1/timesheet/${this.getClient().clientId}/atividades`;
    },
    resource: function () {
      var that = this;
      var resource = this.apiResource(this.resourceUrl);
      return {
        get: function (param) {
          return new Promise((resolve, reject) => {
            resource
              .get(param)
              .then((result) => {
                that.opts.etapas = _.uniqBy(result, "etapa").map(
                  (a) => a.etapa
                );
                resolve(result);
              })
              .catch(reject);
          });
        },
        save: resource.save,
        delete: resource.delete,
      };
    },
  },
  data: function () {
    return {
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
          hideInTable: true,
          type: 0,
        },
        {
          key: "etapa",
          name: "Etapa",
          type: this.$fieldTypes.COMBOBOX,
          rules: [{ rule: "required" }],
          rel: { to: "etapas" },
        },
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          align: 1,
          hideInform: true,
          type: this.$fieldTypes.DATETIME,
        },
        {
          key: "criado_em",
          name: "Data de Criação",
          align: 1,
          hideInform: true,
          type: this.$fieldTypes.DATETIME,
        },
      ],
      opts: {
        etapas: [],
      },
    };
  },
};
</script>

<style>
</style>